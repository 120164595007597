
	import { Component } from "vue-property-decorator";

	import Base from "../Base";
	import SearchModal, { filter } from "./modal/SearchModal.vue";

	import type { SharerModel } from "@/models/Share";

	@Component({ components: { SearchModal } })
	export default class extends Base {
		$refs!: {
			modal: SearchModal;
		}

		protected data: SharerModel[] | null = null;
		protected keyword: string = "";

		beforeMount(): void {
			this.load();
		}

		private async load() {
			this.data = null;
			try {
				this.data = await post<SharerModel[]>("/api/Share");
			} catch(e) {
				this.data = null;
				throw e;
			}
		}

		protected get filtered(): SharerModel[] {
			return filter(this.data, this.keyword);
		}

		protected async search(): Promise<void> {
			this.keyword = (await this.$refs.modal.show())!;
		}
	}
