
	import { Component, Inject, Vue } from "vue-property-decorator";

	import type { SharerModel } from "@/models/Share";

	@Component
	export default class extends Vue {
		@Inject() public readonly alert!: AlertMethod;
		@Inject() public readonly confirm!: ConfirmMethod;

		protected account: string = "";

		protected async send(): Promise<void> {
			try {
				var sharer = await post<SharerModel>("/api/Share/Try", { Id: this.account });
				if(sharer.Added && !await this.confirm("本帳號已有設定過權限", undefined, "warning", "前往查看")) return;
				this.$router.replace("/share/setting/" + sharer.Id);
			} catch(e: unknown) {
				if(e instanceof Error) await this.alert(e.message, undefined, "error");
			}
		}
	}
